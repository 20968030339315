import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from ".."
import {ApiService} from "../core/http/api"
import {StorageName} from "../core/types"

import styles from "../styles/LockScreen.module.scss"

const LockScreen: FC = () => {
  const { mainStore } = useContext(Context)
  const navigate = useNavigate()

  const [password, setPassword] = useState("")

  const signIn = async (event: any) => {
    if (password.length <= 3) {
      message.error("Введите корректный пароль!")
      return
    }

    const res = await ApiService.users.signIn(mainStore.userData.email, password)

    if (res.data.state == 1) {
      mainStore.setUserData(res.data.user)
      mainStore.setToken(res.data.token)
      mainStore.setIsAuth(true)
      mainStore.setLockScreen(false)

      localStorage.setItem(StorageName.USER_DATA, JSON.stringify(res.data.user))
      localStorage.setItem(StorageName.USER_TOKEN, res.data.token)
      localStorage.setItem(StorageName.IS_AUTHED, "true")
      localStorage.removeItem(StorageName.LOCK_SCREEN)
    } else {
      message.error(res.data.message)
    }
  }

  useEffect(() => {

  }, [])

  return (
    <>
      <div className={styles.lockscreen}>
        <div className={styles.page_blink + " " + styles.blue + " " + styles.toped}></div>
        <div className={styles.page_blink + " " + styles.right + " " + styles.toped3}></div>

        <div className={styles.ls_wrap}>
          <div className={styles.ls_wrap_container}>
            <div className={styles.ls_title}>Разблокировать экран</div>
            <div className={styles.ls_form}>
              <div className={styles.ls_form_user}>
                <div className={styles.ls_form_user__ava} style={{backgroundImage: `url(${mainStore.userData.avatar})`}}></div>
                <div className={styles.ls_form_user__data}>
                  <div>{mainStore.userData.name}</div>
                  <div>{mainStore.userData.email}</div>
                </div>
              </div>
              <div className={styles.ls_form_item}>
                <input type="password" placeholder="*********" value={password} onChange={(event) => setPassword(prev => prev = event.target.value)} />
              </div>
              <div className={styles.ls_form_action}>
                <button onClick={signIn}>Войти</button>
              </div>
            </div>
            <div className={styles.ls_copys}><b>PLUMBUM</b> CRM</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(LockScreen)