import { LOCALES } from './locales'
import { HomePageTranslations } from './components/HomeTranslations'
import { HeaderTranslations } from './components/HeaderTranslations'
import { BasketTranslations } from './components/BasketTranslations'

export const messages: any = {
  [LOCALES.ENGLISH]: {
    ...HomePageTranslations.EN,
    ...HeaderTranslations.EN,
    ...BasketTranslations.EN
  },
  [LOCALES.RUSSIAN]: {
    ...HomePageTranslations.RU,
    ...HeaderTranslations.RU,
    ...BasketTranslations.RU
  }
}