import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ApiService } from "./api";
import { API_URL } from "../defaults";
import { isNullOrUndefined } from "../utils";
import { StorageName } from "../types";

const $api = axios.create({
  baseURL: API_URL
});

$api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers!["Authorization"] = `Bearer ${localStorage.getItem(StorageName.USER_TOKEN)}`;
  return config;
});

$api.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, async (error: any) => {
  console.log("INTERCEPT");
  console.log(error.response.data.message);
  if (!isNullOrUndefined(error.response.data.message)) {
    if (error.response.data.message == "Пользователь не авторизован") {
      localStorage.clear()
      // window.location.reload()
      window.location.href = "/"
    }
    if (error.response.data.message == "Смена токена") {
      localStorage.setItem(StorageName.USER_TOKEN, error.response.data.newToken);
      window.location.reload()
    }
  }
  return error;
});

export default $api;