import React from "react";

const About = React.lazy(() => import("../../pages/About"));
const Account = React.lazy(() => import("../../pages/Account/Account"));
const Basket = React.lazy(() => import("../../pages/Account/Basket"));
const Favorites = React.lazy(() => import("../../pages/Account/Favorites"));
const SignIn = React.lazy(() => import("../../pages/Account/SignIn"));
const Catalog = React.lazy(() => import("../../pages/Catalog/Catalog"));
const Compare = React.lazy(() => import("../../pages/Catalog/Compare"));
const Product = React.lazy(() => import("../../pages/Catalog/Product"));
const Contact = React.lazy(() => import("../../pages/Contact"));
const Feed = React.lazy(() => import("../../pages/Feed"));
const Home = React.lazy(() => import("../../pages/Home"));
const Partner = React.lazy(() => import("../../pages/Partner"));
const Price = React.lazy(() => import("../../pages/Price"));
const Construct = React.lazy(() => import("../../pages/Construct"));
const Policy = React.lazy(() => import("../../pages/Policy"));

const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"));
const AdminHome = React.lazy(() => import("../../pages/Admin/pages/Home"));
const AdminOrders = React.lazy(() => import("../../pages/Admin/pages/Orders"));
const AdminSettings = React.lazy(() => import("../../pages/Admin/pages/Settings"));
const AdminSupport = React.lazy(() => import("../../pages/Admin/pages/Support"));
const AdminUsers = React.lazy(() => import("../../pages/Admin/pages/Users"));
const AdminAnalytics = React.lazy(() => import("../../pages/Admin/pages/Home"));
const AdminCatalog = React.lazy(() => import("../../pages/Admin/pages/Catalog/Catalog"));
const AdminCatalogCategories = React.lazy(() => import("../../pages/Admin/pages/Catalog/Categories"));
const AdminNotes = React.lazy(() => import("../../pages/Admin/pages/Notes"));

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  ABOUT = '/about',
  CONTACT = '/contact',
  FEED = '/feed',
  PARTNER = '/partner',
  PRICE = '/price',
  CONSTRUCT = '/create',

  POLICY = '/policy/:type',

  ACCOUNT_SIGN = '/account/signin',
  ACCOUNT_SIGN_ACTION = '/account/signin/:action',
  ACCOUNT = '/account',
  ACCOUNT_SUBPAGE = '/account/:subpage',
  ACCOUNT_BASKET = '/basket',
  ACCOUNT_FAVORITES = '/favorites',

  CATALOG = '/catalog',
  CATALOG_CAT = '/catalog/:cat/',
  CATALOG_CAT_CAT2 = '/catalog/:cat/:cat2/',
  CATALOG_CAT_CAT2_CAT3 = '/catalog/:cat/:cat2/:cat3/',
  CATALOG_CAT_CAT2_CAT3_CAT4 = '/catalog/:cat/:cat2/:cat3/:cat4/',
  CATALOG_COMPARE = '/catalog/compare',
  CATALOG_PRODUCT = '/catalog/product/:product',

  // Admin
  ADMIN_HOME = '/admin',
  ADMIN_HOMEA = '/admin/home',
  ADMIN_ANALYTICS = '/admin/analytics',
  ADMIN_CATALOG = '/admin/catalog',
  ADMIN_CATALOG_PROD = '/admin/catalog/:pid',
  ADMIN_CATALOG_CATS = '/admin/categories',
  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_EDIT = '/admin/users/:uid',
  ADMIN_ORDERS = '/admin/orders',
  ADMIN_ORDERS_ID = '/admin/orders/:id',
  ADMIN_NOTES = '/admin/notes',
  ADMIN_SUPPORT = '/admin/support',
  ADMIN_SETTINGS = '/admin/settings',
  ADMIN_SIGNIN = '/admin/signin'
}

export const routesNames: any = {
  [RouteNames.ADMIN_HOMEA]: "Главная",
  [RouteNames.ADMIN_ANALYTICS]: "Аналитика",
  [RouteNames.ADMIN_CATALOG]: "Каталог",
  [RouteNames.ADMIN_CATALOG_PROD]: "Товар ;;",
  [RouteNames.ADMIN_CATALOG_CATS]: "Категории",
  [RouteNames.ADMIN_USERS]: "Клиенты",
  [RouteNames.ADMIN_USERS_EDIT]: "Клиент ;;",
  [RouteNames.ADMIN_ORDERS]: "Заказы",
  [RouteNames.ADMIN_ORDERS_ID]: "Заказ ;;",
  [RouteNames.ADMIN_NOTES]: "Уведомления",
  [RouteNames.ADMIN_SUPPORT]: "Служба поддержки",
  [RouteNames.ADMIN_SETTINGS]: "Настройки"
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.ABOUT, exact: true, component: <About /> },
  { path: RouteNames.CONTACT, exact: true, component: <Contact /> },
  { path: RouteNames.FEED, exact: true, component: <Feed /> },
  { path: RouteNames.PARTNER, exact: true, component: <Partner /> },
  { path: RouteNames.PRICE, exact: true, component: <Price /> },
  { path: RouteNames.CONSTRUCT, exact: true, component: <Construct /> },
  
  { path: RouteNames.POLICY, exact: true, component: <Policy /> },

  { path: RouteNames.ACCOUNT, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_SUBPAGE, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_SIGN, exact: true, component: <SignIn /> },
  { path: RouteNames.ACCOUNT_SIGN_ACTION, exact: true, component: <SignIn /> },
  { path: RouteNames.ACCOUNT_BASKET, exact: true, component: <Basket /> },
  { path: RouteNames.ACCOUNT_FAVORITES, exact: true, component: <Favorites /> },

  { path: RouteNames.CATALOG, exact: true, component: <Catalog /> },
  { path: RouteNames.CATALOG_CAT, exact: true, component: <Catalog /> },
  { path: RouteNames.CATALOG_CAT_CAT2, exact: true, component: <Catalog /> },
  { path: RouteNames.CATALOG_CAT_CAT2_CAT3, exact: true, component: <Catalog /> },
  { path: RouteNames.CATALOG_CAT_CAT2_CAT3_CAT4, exact: true, component: <Catalog /> },

  { path: RouteNames.CATALOG_COMPARE, exact: true, component: <Compare /> },
  { path: RouteNames.CATALOG_PRODUCT, exact: true, component: <Product /> },

  { path: RouteNames.ADMIN_HOME, exact: true, component: <AdminHome /> },
  { path: RouteNames.ADMIN_HOMEA, exact: true, component: <AdminHome /> },
  { path: RouteNames.ADMIN_ANALYTICS, exact: true, component: <AdminAnalytics /> },
  { path: RouteNames.ADMIN_CATALOG, exact: true, component: <AdminCatalog /> },
  { path: RouteNames.ADMIN_CATALOG_PROD, exact: true, component: <AdminCatalog /> },
  { path: RouteNames.ADMIN_CATALOG_CATS, exact: true, component: <AdminCatalogCategories /> },
  { path: RouteNames.ADMIN_USERS, exact: true, component: <AdminUsers /> },
  { path: RouteNames.ADMIN_USERS_EDIT, exact: true, component: <AdminUsers /> },
  { path: RouteNames.ADMIN_ORDERS, exact: true, component: <AdminOrders /> },
  { path: RouteNames.ADMIN_ORDERS_ID, exact: true, component: <AdminOrders /> },
  { path: RouteNames.ADMIN_SUPPORT, exact: true, component: <AdminSupport /> },
  { path: RouteNames.ADMIN_SETTINGS, exact: true, component: <AdminSettings /> },
  { path: RouteNames.ADMIN_NOTES, exact: true, component: <AdminNotes /> },
  { path: RouteNames.ADMIN_SIGNIN, exact: true, component: <AdminAuth /> }
]

export const twRoutes: IRoute[ ] = []
