import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";

import styles from "../styles/Header.module.scss";

import $ from "jquery";
import { Animated } from "react-animated-css";
import { Select } from "antd";
import { isNullOrUndefined } from "../core/utils";

const Header: FC = () => {

  const { mainStore } = useContext(Context);

  const {width, height} = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = useParams();
  
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuDisplay, setIsMenuDisplay] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [currentLocation, setCurrentLocation] = useState("/");

  const [catsOpts, setCatsOpts] = useState([
    {label: "Категория", value: "all"},
    {label: "Ты", value: "you"},
    {label: "Спорт", value: "sport"},
    {label: "Финансы", value: "fin"},
    {label: "Хобби", value: "hobb"},
    {label: "Удача", value: "luck"},
    {label: "Цель", value: "point"}
  ] as any[]);
  const [catsSelected, setCatsSelected] = useState("all");

  const [floatHeaderVisible, setFloatHeaderVisible] = useState(false);
  const [floatHeaderDisplay, setFloatHeaderDisplay] = useState(false);
  
  const [scrollFixer, setScrollFixer] = useState(0);
  const [animHeader1, setAnimHeader1] = useState(false);
  const [animHeader2, setAnimHeader2] = useState(false);
  const [animHeader3, setAnimHeader3] = useState(false);
  const [animHeader4, setAnimHeader4] = useState(false);

  const openMenu = () => {
    setIsMenuDisplay(true);
    setTimeout(() => {
      setIsMenuVisible(true);
    }, 100);
  }

  const closeMenu = () => {
    setIsMenuVisible(false);
    setTimeout(() => {
      setIsMenuDisplay(false);
    }, 500);
  }

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [isSearchSlided, setIsSearchSlided] = useState(false);

  const openSearch = () => {
    setIsSearchVisible(true);
    setTimeout(() => {
      setIsSearchShown(true);
      setTimeout(() => {
        setIsSearchSlided(true);
      }, 400);
    }, 200);
  }

  const goSearch = () => {}

  const closeSearch = () => {
    setIsSearchSlided(false);
    setTimeout(() => {
      setIsSearchShown(false);
      setTimeout(() => {
        setIsSearchVisible(false);
      }, 400);
    }, 400);
  }

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!isNullOrUndefined(location.search) && location.search != "") {
      setTimeout(() => {
        const prms = new URLSearchParams(decodeURI(location.search));
        if (prms.has("s")) {
          setSearchText(prev => prev = prms.get("s")!);
        }
        if (prms.has("cat")) {
          setTimeout(() => {
            let vv = catsOpts.find(v => v.label == prms.get("cat")!);
            if (vv != undefined) {
              setCatsSelected(prev => prev = vv.value);
            }
          }, 500);
        }
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    if (width <= 1000) {
      setIsMobile(true);
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1000) {
        setIsMobile(prev => {
          if (prev == true) {
            return prev;
          } else {
            return prev = true;
          }
        });
      } else {
        setIsMobile(prev => {
          if (prev == false) {
            return prev;
          } else {
            return prev = false;
          }
        });
      }
    });

    console.log("Scroll Init!");

    let scrFix = 0;

    const onScroll = (event: any) => {
      const scrTop = event.target.scrollingElement.scrollTop

      console.log("scroll head", scrTop, scrFix);

      if (scrTop < scrFix && scrTop > 100) {
        setFloatHeaderVisible(prev => prev = true);
      } else {
        setFloatHeaderVisible(prev => prev = false);
      }
      
      scrFix = scrTop;

      // if (scrTop > 80) {
      //   if ($("#menufloat").css("display") == "none") {
      //     $("#menufloat").css("display", "flex");
      //     setTimeout(() => {
      //       $("#menufloat").css("opacity", "1");
      //     }, 600);
      //   }
      // } else {
      //   if ($("#menufloat").css("display") == "flex") {
      //     $("#menufloat").css("opacity", "0");
      //     setTimeout(() => {
      //       $("#menufloat").css("display", "none");
      //     }, 600);
      //   }
      // }
    }

    setTimeout(() => {
      setAnimHeader1(true);
      setTimeout(() => {
        setAnimHeader2(true);
        setTimeout(() => {
          setAnimHeader3(true);
          setTimeout(() => {
            setAnimHeader4(true);
          }, 200);
        }, 200);
      }, 300);
    }, 100);

    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, []);

  return (
    <>
      <div className={styles.search_wrap} style={{display: isSearchVisible ? "block" : "none"}}>
        <div className={styles.search_wrap__bg} style={{opacity: isSearchShown ? "1" : "0"}} onClick={() => closeSearch()}></div>
        <div className={styles.search_wrap__pane} style={{top: isSearchSlided ? "24px" : "-120px"}}>
          <div className={styles.search_wrap__pane__block}>
            <div className={styles.swpb_wrap}>
              <div className={styles.swpb_wrap__select}>
                <Select
                  className={"swpb_sel"}
                  defaultValue={catsSelected}
                  value={catsSelected}
                  bordered={false}
                  onChange={(event) => setCatsSelected(event)}
                  options={catsOpts}
                  />
              </div>
              <div className={styles.swpb_wrap__det}></div>
              <div className={styles.swpb_wrap__input}>
                <input type="text" value={searchText} onChange={(event) => setSearchText(event.target.value)} placeholder="Что вы хотите найти?" />
              </div>
            </div>
            <button onClick={() => window.location.href = `/catalog?s=${searchText}&cat=${catsOpts.find(v => v.value == catsSelected)!.label}`}><img src="/assets/images/search_btn.svg" /></button>
          </div>
        </div>
      </div>

      <header className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={"col-4 col-md-3 " + styles.col1}>
              <div className={styles.logo} style={{
                position: "absolute",
                transform: animHeader1 ? "scale(1)" : "scale(0.4)",
                opacity: animHeader1 ? "1" : "0"
              }}>
                <a href="/"><img src="/assets/images/logo_w.svg" /></a>
              </div>
            </div>

            <div className={"col-8 col-md-6 " + styles.col2} style={{display: isMobile ? "block":"none"}}>
              <div className={styles.actions}>
                
                <button className={styles.search} style={{
                  transform: animHeader2 ? "scale(1)" : "scale(0.4)",
                  opacity: animHeader2 ? "1" : "0"
                }} onClick={() => openSearch()}><img src="/assets/images/search.svg" /></button>
                <button className={styles.basket} style={{
                  transform: animHeader3 ? "scale(1)" : "scale(0.4)",
                  opacity: animHeader3 ? "1" : "0"
                }} onClick={() => navigate("/basket")}><img src="/assets/images/basket.svg" />  <span>{mainStore.basketCount}</span></button>
                <button className={styles.search} style={{
                  transform: animHeader4 ? "scale(1)" : "scale(0.4)",
                  opacity: animHeader4 ? "1" : "0"
                }} onClick={() => openMenu()}><img src="/assets/images/menu.svg" /></button>
              </div>
            </div>

            <div className="col-6 col-md-6" style={{display: isMobile?"none":"block"}}> 
              <div className={styles.menu}>
                <div>
                  <a onClick={() => navigate("/")} className={styles.menu__item + (currentLocation == "/" ? " " + styles.active : "")}><FormattedMessage id="global_menu_power" /></a>
                  <a onClick={() => navigate("/catalog")} className={styles.menu__item + (currentLocation.includes("catalog") ? " " + styles.active : "")}><FormattedMessage id="global_menu_catalog" /></a>
                  <a onClick={() => navigate("/create")} className={styles.menu__item + (currentLocation.includes("create") ? " " + styles.active : "")}><FormattedMessage id="global_menu_create" /></a>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3" style={{
              alignItems: "flex-end",
              flexDirection: "column",
              display: isMobile ? "none":"flex"
            }}>
              <div className={styles.actions}>
                <button className={styles.account + (currentLocation.includes("account") ? " " + (mainStore.isAuth ? styles.active : styles.active_na) : "")} style={{
                  padding: "8px 20px"
                }} onClick={() => navigate(mainStore.isAuth ? "/account" : "/account/signin")}>{mainStore.isAuth ? <>{mainStore.userData.email}</> : <><FormattedMessage id="global_menu_login" /></>}</button>

                <button className={styles.search} onClick={() => openSearch()}><img src="/assets/images/search.svg" /></button>
                <button className={styles.basket} onClick={() => navigate("/basket")}><img src="/assets/images/basket.svg" /> {mainStore.isAuth ? <span>{mainStore.basketCount}</span> : <></>}</button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={styles.header_float} id="menufloat" style={{
        top: floatHeaderVisible == true ? "24px" : "-48px"
      }}>
        <div className={styles.header_float_wrap}>
          <div className={styles.header_float__logo}><img onClick={() => navigate("/")} src="/assets/images/logo_w.svg" /></div>
          <div className={styles.header_float__menu} style={{display: isMobile ? "none" : "flex"}}>
            <a onClick={() => navigate("/")} className={styles.header_float__menu_item + (currentLocation == "/" ? " " + styles.active : "")}><FormattedMessage id="global_menu_power" /></a>
            <a onClick={() => navigate("/catalog")} className={styles.header_float__menu_item + (currentLocation.includes("catalog") ? " " + styles.active : "")}><FormattedMessage id="global_menu_catalog" /></a>
            <a onClick={() => navigate("/create")} className={styles.header_float__menu_item + (currentLocation.includes("create") ? " " + styles.active : "")}><FormattedMessage id="global_menu_create" /></a>
          </div>
          <div className={styles.header_float__sign} onClick={() => navigate("/account")} style={{display: isMobile ? "none" : "block"}}>{mainStore.isAuth ? <>{mainStore.userData.email}</> : <><FormattedMessage id="global_menu_login" /></>}</div>
          <div className={styles.header_float__search} onClick={() => openSearch()} style={{display: isMobile ? "none" : "block"}}><img src="/assets/images/search.svg" /></div>
          <div className={styles.header_float__basket} onClick={() => navigate("/basket")} style={{display: isMobile ? "none" : "block"}}><img src="/assets/images/basket.svg" /></div>
          {isMobile ? <div className={styles.header_float__mo} onClick={() => openMenu()}><img src="/assets/images/menu.svg" /></div> : <></>}
        </div>
      </div>

      <div className={styles.mobile_menu} style={{
        opacity: isMenuVisible ? "1" : "0",
        display: isMenuDisplay ? "block" : "none",
      }}>
        <div className={styles.mobile_menu__header}>
          <div><a href="/"><img src="/assets/images/logo_w.svg" /></a></div>
          <div><img style={{
            position: "relative",
            top: "-4px"
          }} onClick={() => closeMenu()} src="/assets/images/close.svg" /></div>
        </div>
        <div className={styles.mobile_menu__items}>
          <div>
            <a href="/account/signin">{mainStore.isAuth ? <><span><img src={mainStore.userData.avatar} /></span> {mainStore.userData.name}</> : <><FormattedMessage id="global_menu_login" /></>}</a>
          </div>
          <div><a onClick={() => {
            navigate("/"); closeMenu();
          }}><FormattedMessage id="global_menu_power" /></a></div>
          <div><a onClick={() => {
            navigate("/catalog"); closeMenu();
          }}><FormattedMessage id="global_menu_catalog" /></a></div>
          <div><a onClick={() => {
            navigate("/create"); closeMenu();
          }}><FormattedMessage id="global_menu_create" /></a></div>
        </div>
        <div className={styles.mobile_menu__social}>
          <div className={styles.mobile_menu__social_w}>
            <div>VK</div>
            <div>INST</div>
            <div>FB</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Header)

/*
  <a onClick={() => navigate("/contact")} className={styles.menu__item + (currentLocation == "/contact" ? " " + styles.active : "")}><FormattedMessage id="global_menu_contacts" /></a>

  <a onClick={() => navigate("/contact")} className={styles.header_float__menu_item + (currentLocation == "/contact" ? " " + styles.active : "")}><FormattedMessage id="global_menu_contacts" /></a>

  <div><a onClick={() => {
    navigate("/contact"); closeMenu();
  }}><FormattedMessage id="global_menu_contacts" /></a></div>

<button className={styles.account + (currentLocation.includes("account") ? " " + (mainStore.isAuth ? styles.active : styles.active_na) : "")} style={{
                  padding: mainStore.isAuth ? "8px 9px 8px 9px" : "",
                  transform: animHeader2 ? "scale(1)" : "scale(0.4)",
                  opacity: animHeader2 ? "1" : "0"
                }} onClick={() => navigate(mainStore.isAuth ? "/account" : "/account/signin")}>{mainStore.isAuth ? <><span><img src={mainStore.userData.avatar} /></span></> : <><span><FormattedMessage id="global_menu_login" /></span></>}</button>

*/