import { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import './App.scss';

import AppRouter from './components/AppRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '.';
import { isNullOrUndefined } from './core/utils';
import { StorageName } from './core/types';
import { ApiService } from './core/http/api';
import Header from './components/Header';
import { Logger } from './core/logger';
import { IntlProvider } from 'react-intl';
import { messages } from './core/i18n/translations';
import { LOCALES } from './core/i18n/locales';
import moment from 'moment';
import AHeader from './components/Admin/AHeader';
import Sidebar from './components/Admin/Sidebar';
import ImageViewModal from './components/ModalImageView'
import { SliderToggleDirection, SliderToggleDir, StorageKeys } from './core/defaults'
import LockScreen from './pages/LockScreen'

const App: FC = () => {

  const { mainStore, adminStore } = useContext(Context)
  const location = useLocation()
  const navigate = useNavigate()

  const [locale, setLocale] = useState(LOCALES.RUSSIAN)

  const checkAuth = async () => {
    let userData = localStorage.getItem(StorageName.USER_DATA)
    if (!isNullOrUndefined(userData)) {
      const res = await ApiService.users.getAccount()
      
      let basketCount = localStorage.getItem(StorageName.BASKET_COUNT)
      if (!isNullOrUndefined(basketCount) && basketCount != "") {
        mainStore.setBasketCount(parseInt(basketCount))
      }

      localStorage.setItem(StorageName.USER_DATA, JSON.stringify(res.data.user))
      //localStorage.setItem(StorageName.USER_TOKEN, res.data.token)
      mainStore.setUserData(res.data.user)
      mainStore.setToken(localStorage.getItem(StorageName.USER_TOKEN)!)
      mainStore.setIsAuth(true)
    } else {
      mainStore.setIsAuth(false)
    }

    fetchData()
  }

  const fetchData = async () => {
    if (mainStore.userData.role == "ADMIN") {
      const res = await ApiService.crm.notes.getList({
        limit: 50,
        page: 0,
        type: ""
      })

      adminStore.setNotes(res.data.notes)
      adminStore.setNotesTotal(res.data.total)
      adminStore.setNotesTotalPages(res.data.totalPages)
      adminStore.setNotesPage(0)
      adminStore.setNotesType("")
    }

    fetchSettings()
  }

  const fetchSettings = async () => {
    const homeSlRestore = localStorage.getItem(StorageKeys.SETTINGS_HOME_SLIDER)
    const homeBxRestore = localStorage.getItem(StorageKeys.SETTINGS_HOME_BOXES)
    const homePrRestore = localStorage.getItem(StorageKeys.SETTINGS_HOME_PRODS)
    if (homeSlRestore != null) {
      mainStore.setHomeSliderImages(JSON.parse(homeSlRestore))
      mainStore.setHomeSliderTitle(JSON.parse(homeSlRestore)[0].title)
    }
    if (homeBxRestore != null) {
      mainStore.setHomeBoxes(JSON.parse(homeBxRestore))
    }
    if (homePrRestore != null) {
      mainStore.setHomeProducts(JSON.parse(homePrRestore))
    }

    const res = await ApiService.core.getSettings()

    const settings: any[] = res.data.settings
    const homeSlider = settings.find(v => v.name == "home_slider")
    const homeProducts = settings.find(v => v.name == "home_products")
    const homeBoxes = settings.find(v => v.name == "home_boxes")

    localStorage.setItem(StorageKeys.SETTINGS_HOME_SLIDER, JSON.stringify(homeSlider.data))
    localStorage.setItem(StorageKeys.SETTINGS_HOME_BOXES, JSON.stringify(homeBoxes.data))
    localStorage.setItem(StorageKeys.SETTINGS_HOME_PRODS, JSON.stringify(homeProducts.data))

    mainStore.setHomeBoxes(homeBoxes.data)
    mainStore.setHomeProducts(homeProducts.data)

    mainStore.setHomeSliderImages(homeSlider.data)
    mainStore.setHomeSliderTitle(homeSlider.data[0].title)
    
    mainStore.setIsSettingsLoaded(true)
  }

  const changeLocale = (locale: string) => {
    localStorage.setItem("lang_init", locale == LOCALES.ENGLISH ? "en" : "ru");
    setLocale(locale);
  }

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      document.body.style.backgroundColor = "#eff3f7"
    } else {
      document.body.style.backgroundColor = "#000"
    }
  }, [location.pathname])

  useEffect(() => {
    setTimeout(() => {
      mainStore.setIsFaderVisible(false)
      setTimeout(() => {
        mainStore.setIsFaderShown(false)
      }, 800)
    }, 100)

    checkAuth()

    moment.locale("ru")

    const lang = localStorage.getItem("lang_init")
    if (!isNullOrUndefined(lang)) {
      setLocale(lang == "ru" ? LOCALES.RUSSIAN : LOCALES.ENGLISH)
    }

    const lScreen = localStorage.getItem(StorageName.LOCK_SCREEN)
    if (!isNullOrUndefined(lScreen)) {
      mainStore.setLockScreen(true)
    }

    console.log("Load App!")
  }, []);

  return (
    !location.pathname.includes("admin") && mainStore.isSettingsLoaded ? <>
      <div className="fader" style={{opacity: mainStore.isFaderVisible ? "1" : "0", display: mainStore.isFaderShown ? "block" : "none"}}></div>
      
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
            <Header />

            <AppRouter />

            <ToastContainer />
          </IntlProvider>
        </div>
      </div>
    </> : mainStore.isSettingsLoaded ? (mainStore.lockScreen ? <>
      <LockScreen />
    </> : <>
      {location.pathname == "/admin/signin" ? <div id="layout-wrapper">
        <AppRouter />
      </div> : <div id="layout-wrapper">
        <AHeader />
        <Sidebar />
        
        <div className="main-content">
          <div className="page-content">
            <AppRouter />
          </div>
        </div>
      </div>}

      <ImageViewModal
        visible={mainStore.isImageViewVisible}
        images={mainStore.selectedImages}
        selectedIndex={mainStore.selectedImageIndex}
        onToggleImage={(dir: SliderToggleDirection) => {
          if (dir == SliderToggleDir.NEXT) {
            if (mainStore.selectedImageIndex < (mainStore.selectedImages.length - 1)) {
              mainStore.setSelectedImageIndex(mainStore.selectedImageIndex + 1)
            } else {
              mainStore.setSelectedImageIndex(0)
            }
          } else {
            if (mainStore.selectedImageIndex > 0) {
              mainStore.setSelectedImageIndex(mainStore.selectedImageIndex - 1)
            } else {
              mainStore.setSelectedImageIndex(mainStore.selectedImages.length - 1)
            }
          }
        }}
        onClose={() => mainStore.setIsImageViewVisible(false)} />

      <ToastContainer />
    </>
  ) : <></>)
}

export default observer(App);

/**
 <div className="fader_content" style={{opacity: mainStore.isFaderContVisible ? "1" : "0", display: mainStore.isFaderContShown ? "block" : "none"}}></div>

 */