import { makeAutoObservable } from "mobx";
import {LOCALES} from "../i18n/locales";
import {XImage} from "../models/XImage";
import { StorageName } from "../types";
import { isNullOrUndefined } from "../utils";

export default class MainStore {

  isAuth = false
  lockScreen = false
  token: string = ""
  basketCount: number = 0
  scrollFixer: number = 0
  userData: any = {}

  locale: string = LOCALES.RUSSIAN

  isFaderVisible: boolean = true
  isFaderShown: boolean = true

  isFaderContVisible: boolean = true
  isFaderContShown: boolean = true

  activePanel: string | string[] | undefined = undefined

  lastImageBtm: number = 0

  isProductDataBottom: boolean = false
  productDataBottom: number = 0

  isImageViewVisible: boolean = false
  selectedImages: XImage[] = []
  selectedImageIndex: number = 0

  searchResults: any[] = []

  globalSearchText: string = ""

  policyMenuSel: string = "0"
  policyMenuFixed: boolean = false
  policyMenuVal: number = 0
  policyMenuWidth: number = 0

  homeSliderImages: any[] = []
  homeSliderTitle: string = ""

  homeProducts: any | null = null
  homeBoxes: any[] = []
  selectedBox: any | null = null

  anim2: boolean = false
  
  sidebarVisible: boolean = false
  isSettingsLoaded: boolean = false

  constructor() {
    makeAutoObservable(this)
  }
  
  setIsSettingsLoaded(val: boolean) {
    this.isSettingsLoaded = val
  }

  setSelectedBox(val: any) {
    this.selectedBox = val
  }

  setSidebarVisible(val: boolean) {
    this.sidebarVisible = val
  }

  setHomeProducts(val: any) {
    this.homeProducts = val
  }

  setHomeBoxes(val: any[]) {
    this.homeBoxes = val
  }

  setAnim2(val : boolean) {
    this.anim2 = val
  }

  setHomeSliderTitle(val: string) {
    this.homeSliderTitle = val
  }

  setHomeSliderImages(val: any[]) {
    this.homeSliderImages = val
  }

  setPolicyMenuSel(val: string) {
    this.policyMenuSel = val
  }

  setPolicyMenuWidth(val: number) {
    this.policyMenuWidth = val
  }

  setPolicyMenuFixed(val: boolean) {
    this.policyMenuFixed = val
  }

  setPolicyMenuVal(val: number) {
    this.policyMenuVal = val
  }

  setGlobalSearchText(val: string) {
    this.globalSearchText = val
  }

  setSearchResults(val: any[]) {
    this.searchResults = val
  }

  setIsImageViewVisible(val: boolean) {
    this.isImageViewVisible = val
  }

  setSelectedImageIndex(val: number) {
    this.selectedImageIndex = val;
  }

  setSelectedImages(val: XImage[]) {
    this.selectedImages = val;
  }

  setIsProductDataBottom(bl: boolean) {
    this.isProductDataBottom = bl;
  }
  
  setProductDataBottom(btm: number) {
    this.productDataBottom = btm;
  }

  setLastImageBtm(btm: number) {
    this.lastImageBtm = btm;
  }

  setActivePanel(bl: string | string[] | undefined) {
    this.activePanel = bl;
  }

  setIsFaderContVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsFaderContShown(bl: boolean) {
    this.isFaderShown = bl;
  }

  setIsFaderVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsFaderShown(bl: boolean) {
    this.isFaderShown = bl;
  }

  setUserData(data: any) {
    this.userData = data;
  }

  setBasketCount(count: number) {
    localStorage.setItem(StorageName.BASKET_COUNT, `${count}`);
    this.basketCount = count;
  }

  plusBasket() {
    this.basketCount++;
  }

  setIsAuth(bl: boolean) {
    this.isAuth = bl;
  }

  setLockScreen(val: boolean) {
    this.lockScreen = val
  }
  
  setScrollFixer(token: number) {
    this.scrollFixer = token;
  }
  
  setToken(token: string) {
    this.token = token;
  }

  getUserData() {
    return localStorage.getItem("user_data");
  }

  isUserAccessed() {
    const data = localStorage.getItem("user_data");
    if (isNullOrUndefined(data)) {
      return false;
    }

    try {
      const user = JSON.parse(data);
      if (user.collection.nfts.length > 0) {
        return true;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  isUserAuthed() {
    const data = localStorage.getItem("user_data");
    if (isNullOrUndefined(data)) {
      return false;
    }
    return true;
  }

  isUserPaid() {
    return this.userData.role == "USER_PAID";
  }

}