import {message} from "antd";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import {Context} from "../..";
import {ApiService} from "../../core/http/api";
import {INote} from "../../core/models/Note";
import {RouteNames} from "../../core/router";
import { StorageName } from "../../core/types";
import {getDateAgo} from "../../core/utils";
import GlobalTabs from "./GlobalTabs/GlobalTabs";

const AHeader: FC = () => {

  const { mainStore, adminStore } = useContext(Context)
  const navigate = useNavigate()

  const [focused, setFocused] = useState(false)

  const logout = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    localStorage.clear()
    mainStore.setIsAuth(false)
    mainStore.setUserData(null)
    mainStore.setToken("")
    navigate(RouteNames.ADMIN_SIGNIN)
  }

  const lockScreen = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    mainStore.setLockScreen(true)
    localStorage.setItem(StorageName.LOCK_SCREEN, "1")
  }

  const readAllNotes = async () => {
    const res = await ApiService.crm.notes.readNotes({
      ids: "all"
    })

    const res2 = await ApiService.crm.notes.getList({
      limit: 50,
      page: 0,
      type: ""
    })

    adminStore.setNotes(res2.data.notes)

    message.success("Все уведомления прочитаны!")
  }

  const getNoteUrl = (note: INote) => {
    if (note.type!.includes("account_")) {
      return `/admin/users/${note.data.user_id}`
    } else if (note.type!.includes("order_")) {
      return `/admin/orders/${note.data.order_id}`
    } else {
      return "#"
    }
  }

  const fetchSearchResults = async () => {
    if (mainStore.globalSearchText.length < 3) {
      mainStore.setSearchResults([])
      return
    }

    const res = await ApiService.crm.globalSearch({
      search: mainStore.globalSearchText,
      limit: 20,
      page: 0
    })

    console.log(res)

    mainStore.setSearchResults(res.data.results)
  }

  useEffect(() => {
    fetchSearchResults()
  }, [mainStore.globalSearchText])

  return (
    <>
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex" style={{position: "relative"}}>
          <button onClick={() => {
            mainStore.setSidebarVisible(!mainStore.sidebarVisible)
          }} type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
            <i className="fa fa-fw fa-bars"></i>
          </button>

          <form className="app-search d-none d-lg-block" style={{width: "340px"}}>
            <div className="position-relative">
              <input type="text" className="form-control" value={mainStore.globalSearchText} onChange={(event) => mainStore.setGlobalSearchText(event.target.value)} onFocus={() => setFocused(prev => prev = true)} onBlur={() => {
                setTimeout(() => {
                  setFocused(prev => prev = false)
                }, 300);
              }} placeholder="Поиск товаров или пользователей..." />
              <span className="ri-search-line"></span>
            </div>
          </form>

          {focused ? <div className="search_results">
            {mainStore.searchResults.length == 0 ? <div className="sr_none">Результаты не найдены...</div> : <></>}
            <div className="sr_items">
              {mainStore.searchResults.map(result => <div className="sr_items__item" onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                console.log(result)
                if (result.type == "user") {
                  navigate(`/admin/users/${result.id}`)
                } else if (result.type == "product") {
                  navigate(`/admin/catalog/${result.id}`)
                }
              }}>
                <div className="sr_items__item_image" style={{backgroundImage: `url(${result.image})`}}></div>
                <div className="sr_items__item_name">{result.name}</div>
                <div className="sr_items__item_go"><i className="far fa-arrow-right-long"></i></div>
              </div>)}
            </div>
          </div> : <></>}
        </div>

        <div className="d-flex">

          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="ri-search-line"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
              <form className="p-3">
                <div className="mb-3 m-0">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search ..." />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit"><i className="ri-search-line"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="dropdown d-inline-block">
            <GlobalTabs items={adminStore.globalTabs} />
          </div>

          <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="ri-notification-3-line"></i>
              {adminStore.isUnreadNotesExists() ? <span className="noti-dot"></span> : <></>}
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0"> Уведомления </h6>
                  </div>
                  {adminStore.isUnreadNotesExists() ? <div className="col-auto">
                    <a href="#" className="small" onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()

                      readAllNotes()
                    }}>Прочитать все</a>
                  </div> : <></>}
                </div>
              </div>
              <div className="superbar" style={{maxHeight: "230px", overflowY: "scroll"}}>
                {adminStore.notes.map(note => <a 
                  href={getNoteUrl(note)}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    const url = getNoteUrl(note)
                    if (url != "#") {
                      navigate(url)
                    }
                  }}
                  className={"text-reset notification-item" + (note.status_looked == 0 ? " notlooked" : "")}>
                  <div className="d-flex align-items-center">
                    <div className="avatar-xs me-3 mt-1">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="ri-shopping-cart-line"></i>
                      </span>
                      {note.status_looked == 0 ? <div className="noti-top-icon">
                        <i className="mdi mdi-heart text-white bg-danger"></i>
                      </div> : <></>}
                    </div>
                    <div className="flex-grow-1 text-truncate">
                      <h6 className="mt-0 mb-1">{note.subject} <span className="mb-1 text-muted fw-normal"></span></h6>
                      <p className="supmt" dangerouslySetInnerHTML={{__html: `${note.message}`}}></p>
                      <p className="suppt mb-0 font-size-12"><i className="mdi mdi-clock-outline"></i> {getDateAgo(note.createdAt!)}</p>
                    </div>
                  </div>
                </a>)}
              </div>
              <div className="p-2 border-top">
                <div className="d-grid">
                  <a className="btn btn-sm btn-link font-size-14 text-center" href="/admin/notes" onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    navigate("/admin/notes")
                  }}>
                    <i className="mdi mdi-arrow-right-circle me-1"></i> Посмотреть остальные...
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="dropdown d-inline-block user-dropdown">
            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img className="rounded-circle header-profile-user" src={mainStore.userData.avatar} alt="Header Avatar" />
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0">{mainStore.userData.name}</h6>
                  </div>
                  <div className="col-auto">
                    <small className="small">{mainStore.userData.email}</small>
                  </div>
                </div>
              </div>
              <div data-simplebar style={{
                maxHeight: "230px"
              }}>
                <a href="#" onClick={lockScreen} className="text-reset notification-item">
                  <div className="d-flex align-items-center">
                    <div className="avatar-xs me-2">
                      <span className="avatar-title bg-soft-primary rounded-circle font-size-16">
                        <i className="ri-lock-unlock-line text-primary"></i> 
                      </span>
                    </div>
                    <div className="flex-grow-1 text-truncate">
                      <h6 className="mb-1">Заблокировать экран</h6>
                      <p className="mb-0 font-size-12">Защитите данные пока будете отсутствовать..</p>
                    </div>
                  </div>
                </a>
              </div>
              
              <div className="pt-2 border-top">
                <div className="d-grid">
                  <a className="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)" onClick={logout}>
                    <i className="ri-shut-down-line align-middle me-1"></i> Выйти из панели
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </header>
    </>
  )
}

export default observer(AHeader)