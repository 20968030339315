import { API_URL } from "../defaults";
import $api from "./http";
import { ControllerName } from "../types";

export class ApiService {

  constructor() {}

  static users = {
    signIn: async (login: string, password: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin`), {login, password});
    },
    reauthWithGoogle: async (jwtToken: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `reauth/google`), {token: jwtToken});
    },
    signInWithGoogle: async (jwtToken: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin/google`), {token: jwtToken});
    },
    signUp: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signup`), {...dto});
    },
    restore: async (login: string, password: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `restore`), {login, password});
    },
    restoreAccept: async (code: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `restore/accept/${code}`), {});
    },
    getAccount: async () => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `account`), {});
    },
    updateAccount: async (dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.USERS, `account`), {...dto});
    },
    updateAccountById: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.USERS, `account/${id}`), {...dto});
    },
    favorite: {
      get: async () => {
        return await $api.get(this.buildUrl(ControllerName.USERS, `favorites`));
      },
      add: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.USERS, `favorites`), {...dto});
      },
      update: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.USERS, `favorites/${id}`), {...dto});
      },
      remove: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.USERS, `favorites/${id}`));
      }
    },
    basket: {
      getBasket: async () => {
        return await $api.get(this.buildUrl(ControllerName.USERS, `basket`), {});
      },
      removeItem: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.USERS, `basket/${id}`), {});
      },
      createPayment: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.USERS, `basket/payment`), dto);
      },
      checkPayment: async () => {
        return await $api.post(this.buildUrl(ControllerName.USERS, `basket/payment/checker`), {})
      }
    }
  }

  static core = {
    getMethods: async () => {
      return await $api.get(this.buildUrl(ControllerName.CRM, `methods`));
    },
    getSettings: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `settings`))
    },
    updateSetting: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CORE, `settings/${id}`), dto)
    },
    getImageRes: async (filename: string) => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `image/${filename}`))
    }
  }

  static crm = {
    globalSearch: async (query: {
      search: string
      limit: number
      page: number
    }) => {
      return await $api.get(this.buildUrl(ControllerName.CRM, `search/global${this.buildParams(query)}`))
    },
    getStats: async (dto: {ts_type: "day" | "week" | "month" | "year"}) => {
      return await $api.post(this.buildUrl(ControllerName.CRM, `stats`), dto)
    },
    getHotBaskets: async () => {
      return await $api.get(this.buildUrl(ControllerName.CRM, `baskets/hot`))
    },
    getOrders: async (query: {
      cats?: string
      search?: string
      sortBy?: string
      sortAlign?: string
      page?: number,
      limit?: number,
      byuser?: string
    }) => {
      return await $api.post(this.buildUrl(ControllerName.CRM, `orders${this.buildParams(query)}`), query)
    },
    getUsers: async (query: {
      limit?: number
      search?: string
      page?: number,
      type?: number
    }) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `list${this.buildParams(query)}`))
    },

    notes: {
      getList: async (query: {
        limit?: number,
        page?: number,
        type?: string
      }) => {
        return await $api.get(this.buildUrl(ControllerName.CRM, `notes${this.buildParams(query)}`))
      },
      readNotes: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CRM, `notes/read`), dto)
      },
      removeNote: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.CRM, `notes/${id}`))
      }
    }
  }

  static catalog = {
    addToBasket: async (opts: {
      product_id: number;
      user_id: number;
      amount: number;
      status: number;
    }) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `basket`), opts);
    },
    getProduct: async (subname: string) => {
      return await $api.get(this.buildUrl(ControllerName.CATALOG, `products/${subname}`), {});
    },
    getProducts: async (query: {
      cats?: string; 
      search?: string;
      sortBy?: string;
      sortAlign?: string;
      page?: number;
      subname?: string;
      limit?: number;
    }) => {
      return await $api.get(this.buildUrl(ControllerName.CATALOG, `products${this.buildParams(query)}`));
    },
    createProduct: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CATALOG, `products`), dto);
    },
    updateProduct: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CATALOG, `products/${id}`), dto);
    },
    deleteProduct: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.CATALOG, `products/${id}`));
    },
    cats: {
      getCategories: async () => {
        return await $api.get(this.buildUrl(ControllerName.CATALOG, `cats`));
      },
      createCategory: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CATALOG, `cats`), dto);
      },
      updateCategory: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.CATALOG, `cats/${id}`), dto);
      },
      deleteCategory: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.CATALOG, `cats/${id}`));
      }
    },
    compare: {
      createCompare: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CATALOG, `compare`), dto);
      },
      deleteCompare: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.CATALOG, `compare/${id}`));
      }
    },
    rate: {
      createRate: async (id: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CATALOG, `products/${id}/rate`), dto);
      },
      updateProductRate: async (id: number, rid: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.CATALOG, `products/${id}/rate/${rid}`), dto);
      },
      deleteProductRate: async (id: number, rid: number) => {
        return await $api.delete(this.buildUrl(ControllerName.CATALOG, `products/${id}/rate/${rid}`));
      }
    },
    reviews: {
      createProductReview: async (id: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CATALOG, `products/${id}/review`), dto);
      },
      updateProductReview: async (id: number, rid: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.CATALOG, `products/${id}/review/${rid}`), dto);
      },
      deleteProductReview: async (id: number, rid: number) => {
        return await $api.delete(this.buildUrl(ControllerName.CATALOG, `products/${id}/review/${rid}`));
      }
    },
    orders: {
      create: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CATALOG, "orders/create"), {...dto});
      },
      payOrder: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.CATALOG, "orders/pay"), {...dto});
      },
      remove: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.CATALOG, `orders/remove/${id}`))
      },
      update: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.CATALOG, `orders/update/${id}`), dto)
      },
      getOrders: async () => {
        return await $api.get(this.buildUrl(ControllerName.CATALOG, "orders/get"));
      }
    }
  };

  static chats = {
    signInChat: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.CHATS, `sign`), dto);
      return data;
    },
    createChatRoom: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.CHATS, `create`), dto);
      return data;
    },
    deleteChatRoom: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.CHATS, `list/${id}`));
      return data;
    },
    getTelegramFeed: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.CHATS, `posts`));
      return data;
    },
    getTelegramFeedImage: async (name: string) => {
      const data = await $api.get(this.buildUrl(ControllerName.CHATS, `posts/image/${name}`));
      return data;
    },
    getMyUnreaded: async (userId: number) => {
      const data = await $api.post(this.buildUrl(ControllerName.CHATS, `myunread`), {userId});
      return data;
    }
  }

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildUrl2(
    controller: ControllerName,
    method: string
  ) {
    return `${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }

}