import {CloseOutlined, DeleteOutlined} from "@ant-design/icons"
import { FC, useContext, useEffect, useState } from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Context} from "../../.."
import {routesNames} from "../../../core/router"

import styles from "./GlobalTabs.module.scss"
import { IGTab } from "./gt.model"

interface GlobalTabsProps {
  items: IGTab[]
  onNewTabAdded?: (tab: IGTab) => void
}

const GlobalTabs: FC<GlobalTabsProps> = ({
  items, onNewTabAdded
}) => {

  const { adminStore } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    console.log("GlobalTabs", location)
    
    if (location.pathname.includes("admin")) {
      let urlPr = location.pathname.split("/")
    
      if (urlPr.length == 3) {
        const exists = adminStore.globalTabs.find(v => v.url == location.pathname)
        if (exists == undefined) {
          const ttl = routesNames[location.pathname]
          adminStore.addGlobalTab({ title: ttl, url: location.pathname })
        }
      } else if (urlPr.length == 4) {
        const exists = adminStore.globalTabs.find(v => v.url == location.pathname)
        if (exists == undefined) {
          const ttl = `/${urlPr[1]}/${urlPr[2]}/:${urlPr[2] == "users" ? "uid" : (urlPr[2] == "orders" ? "id" : "pid")}`
          const rname = routesNames[ttl]
          console.log(ttl, rname)
          adminStore.addGlobalTab({ title: rname.replace(";;", `№${urlPr[3]}`), url: location.pathname })
        }
      }
    }
  }, [location.pathname])

  useEffect(() => {
    const exists = adminStore.globalTabs.find(v => v.url == "/admin/home")
    if (exists == undefined) {
      adminStore.addGlobalTab({ title: "Главная", url: "/admin/home" })
    }
  }, [])

  return (
    <>
      <div className={styles.xgtabs_wrap + (items.length >= 5 ? " " + styles.scr : "")}>
        <div className={styles.xgtabs_cont}>
          <div className={styles.xgtabs} id="glob_tabs">
            {items.map(item => <a className={styles.xgtabs_item + (item.url == location.pathname ? " " + styles.active : "") + (item.title == "Главная" ? " " + styles.fw : "")} href={item.url} onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              navigate(item.url)
            }}>{item.title} <span style={{display: item.title != "Главная" ? "inline-block" : "none"}} className={styles.delete} onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              adminStore.removeGlobalTab(item)
            }}><CloseOutlined /></span></a>)}
          </div>
        </div>
      </div>
    </>
  )
}

export default GlobalTabs
