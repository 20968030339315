export const BasketTranslations = {
  "EN": {
    basket_title: "Basket",
    basket_select_all: "Select All",
    basket_total: "total",
    basket_sale: "Discount",
    basket_ship: "Shipping",
    basket_date: "date",
    basket_pay: "Payment",
    basket_order: "Checkout",
    basket_user_data: "Your data",
    basket_user_name: "Name",
    basket_user_email: "Email",
    basket_user_phone: "Phone",
    basket_save: "Save",
    basket_city: "City",
    basket_addr: "Address",
    basket_comment: "Comment",
    basket_comment_ph: "Your wishes for the order",
    basket_city_ph: "Moscow",
    basket_addr_ph: "Lenin street, 1, apt 1",
    basket_order_txt: "Are you sure you want to checkout?",
    basket_order_act: "Checkout",
    basket_del: "Delete item",
    basket_del_txt: "Are you sure you want to remove this item from your cart?",
    basket_del_act: "Delete",
    basket_ship_err: "Specify the exact shipping address, and if necessary, a comment to the order",
    basket_ud_err: "Fill in details",
    basket_ud_err_txt: "Fill in your personal details to checkout"
  },
  "RU": {
    basket_title: "Корзина",
    basket_select_all: "Выбрать все",
    basket_total: "Итого",
    basket_sale: "Скидка",
    basket_ship: "Доставка",
    basket_date: "Дата",
    basket_pay: "Оплата",
    basket_order: "Оформить заказ",
    basket_user_data: "Ваши данные",
    basket_user_name: "Имя",
    basket_user_email: "Email",
    basket_user_phone: "Телефон",
    basket_save: "Сохранить",
    basket_city: "Город",
    basket_addr: "Адрес",
    basket_comment: "Комментарий",
    basket_comment_ph: "Ваши пожелания к заказу",
    basket_city_ph: "Москва",
    basket_addr_ph: "ул Ленина, 1, кв 1",
    basket_order_txt: "Вы действительно хотите оформить заказ?",
    basket_order_act: "Оформить",
    basket_del: "Удалить товар",
    basket_del_txt: "Вы действительно хотите удалить этот товар из корзины?",
    basket_del_act: "Удалить",
    basket_ship_err: "Укажите точный адрес доставки, и при необходимости коментарий к заказу",
    basket_ud_err: "Заполните данные",
    basket_ud_err_txt: "Заполните ваши личные данные для оформления заказа"
  }
};