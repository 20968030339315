import { makeAutoObservable } from "mobx";
import {IGTab} from "../../components/Admin/GlobalTabs/gt.model";
import {INote} from "../models/Note";

export default class AdminStore {
  
  public notes: INote[] = []
  public notesList: INote[] = []
  public notesType: string = ""
  public notesPage: number = 0
  public notesTotal: number = 0
  public notesTotalPages: number = 0

  globalTabs: IGTab[] = []

  constructor() {
    makeAutoObservable(this)
  }

  addGlobalTab(val: IGTab) {
    this.globalTabs = [...this.globalTabs, val]
  }

  removeGlobalTab(val: IGTab) {
    this.globalTabs = this.globalTabs.filter(v => v.url != val.url)
  }

  clearGlobalTabs() {
    this.globalTabs = []
  }

  isUnreadNotesExists() {
    return this.notes.filter(v => v.status_looked == 0).length > 0
  }

  setNotes(val: INote[]) {
    this.notes = val
  }

  setNotesList(val: INote[]) {
    this.notesList = val
  }

  setNotesTotal(val: number) {
    this.notesTotal = val
  }

  setNotesTotalPages(val: number) {
    this.notesTotalPages = val
  }
  
  setNotesPage(val: number) {
    this.notesPage = val
  }

  setNotesType(val: string) {
    this.notesType = val
  }

}