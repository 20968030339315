import "moment/locale/ru"
import moment from "moment";
import { API_URL } from "./defaults";
import { ControllerName } from "./types"

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  moment.locale("ru");
  return moment(date).format(format);
}

export const getDateTSFormat = (ts: number, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(ts).format(format);
}

export const getDateAgo = (dt: string) => {
  moment.locale("ru")
  moment().locale("ru")
  return moment(dt).locale("ru").fromNow()
}

export function translit(word: string) {
	let answer: string = '';
	const converter: any = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',   ' ': '-',    '&': '-',
    '«': '',     '»': '', '': ''
	};
 
	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined){
			answer += word[i];
		} else {
			answer += converter[word[i]];
		}
	}
 
	return answer;
}

export const getOrderStatusName = (status: number) => {
  if (status == 1) {
    return "Оформлен. Ожидает оплаты..."
  } else if (status == 2) {
    return "Оплачен"
  } else if (status == 3) {
    return "Подтвержден"
  } else if (status == 4) {
    return "Доставка"
  } else if (status == 5) {
    return "Доставлен"
  } else {
    return "Отменен"
  }
}

export function isElementInViewport(el?: any | null): boolean {
  if (el == undefined || el == null) {
    return false;
  }
  
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while(el!.offsetParent) {
    el = el!.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  console.log(top, window.pageYOffset + window.innerHeight, window.pageYOffset)

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth)
  );
}

export const buildUrl = (
  controller: ControllerName,
  method: string
) => {
  return `${API_URL}${controller}/${method}`
}

export const fixNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}

export function getStatusName(status: number) {
  if (status == 1) {
    return "Оформлен. Ожидает оплаты..."
  } else if (status == 2) {
    return "Оплачен"
  } else if (status == 3) {
    return "Подтвержден"
  } else if (status == 4) {
    return "Доставка"
  } else if (status == 5) {
    return "Доставлен"
  } else if (status == 6) {
    return "Отменен"
  } else {
    return "Unknown"
  }
}