export const HeaderTranslations = {
  "EN": {
    global_menu_login: "Login",
    global_menu_power: "Plumbum Power",
    global_menu_catalog: "Catalog",
    global_menu_create: "Constructor",
    global_menu_contacts: "Contacts"
  },
  "RU": {
    global_menu_login: "Вход",
    global_menu_power: "Сила Plumbum",
    global_menu_catalog: "Каталог",
    global_menu_create: "Конструктор",
    global_menu_contacts: "Контакты"
  }
}