export enum ControllerName {
  USERS = "/users",
  CATALOG = "/catalog",
  CORE = "/core",
  CRM = "/crm",
  CHATS = "/chats"
}

export enum AccountPageIndex {
  SUBSCRIBES = "subscribes",
  INFO = "info",
  VIEWS = "views",
  SEE_LATER = "see_later",
  SETTINGS = "settings"
}

export const StorageName = {
  USER_DATA: "nx_shop_user_data",
  USER_TOKEN: "nx_shop_user_token",
  GOOGLE_TOKEN: "nx_shop_google_token",
  IS_AUTHED: "nx_shop_user_authed",
  BASKET_COUNT: "nx_shop_basket_count",
  LOCK_SCREEN: "nx_shop_lock_screen"
};