import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Context} from "../..";
import useWindowDimensions from "../../core/hooks/useWindowDimensions";

import styles from "../../styles/Admin/Sidebar.module.scss";

interface MenuSubItem {
  name: string;
  icon: string;
  navigate: string;
}

interface MenuItem {
  name: string;
  items: MenuSubItem[];
}

const activeMenu: MenuItem[] = [
  {
    name: "Основное",
    items: [
      {name: "Аналитика", icon: "chart-simple", navigate: "/admin/home"}
    ]
  },{
    name: "Управление",
    items: [
      {name: "Категории", icon: "layer-group", navigate: "/admin/categories"},
      {name: "Товары", icon: "cubes-stacked", navigate: "/admin/catalog"},
      {name: "Клиенты", icon: "users", navigate: "/admin/users"},
      {name: "Заказы", icon: "list-check", navigate: "/admin/orders"}
    ]
  },{
    name: "Модерация",
    items: [
      {name: "Поддержка", icon: "ri-chat-1-line", navigate: "/admin/support"},
      {name: "Настройки", icon: "gears", navigate: "/admin/settings"},
      {name: "Уведомления", icon: "book", navigate: "/admin/notes"}
    ]
  }
];

const Sidebar: FC = () => {

  const { mainStore } = useContext(Context)

  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useWindowDimensions()

  const [menu, setMenu] = useState(activeMenu)

  return (
    <>
      <div className="vertical-menu" style={{
        display: "block",
        left: width > 960 ? "0px" : (mainStore.sidebarVisible ? "0px" : "-300px")
      }}>

        <div className="navbar-brand-box">
          <a href="/admin/home" onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            navigate("/admin/home")
          }} className="logo logo-light">
            <span className="logo-sm">PLUMBUM <small>CRM</small></span>
            <span className="logo-lg">PLUMBUM <small>CRM</small></span>
          </a>
        </div>

        <button type="button" className="btn btn-sm px-3 font-size-16 header-itemwaves-effect vertical-menu-btn">
          <i className="fa fa-fw fa-bars"></i>
        </button>

        <div data-simplebar className="sidebar-menu-scroll">

          <div className={styles.sidebar_menu} id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              
              {menu.map(item => <>
                <li className="menu-title">{item.name}</li>

                {item.items.map((subi: MenuSubItem) => <>
                  <li>
                    <a onClick={() => {
                      mainStore.setSidebarVisible(false)
                      navigate(subi.navigate)
                    }} className={"waves-effect" + (subi.navigate == location.pathname ? " " + styles.active : "")}>
                      <span><i className={subi.icon.includes("ri-chat") ? subi.icon : "fas fa-" + subi.icon}></i></span>
                      <span>{subi.name}</span>
                    </a>
                  </li>
                </>)}
              </>)}

            </ul>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default observer(Sidebar)